<template>
  <v-container fluid>
    <v-row justify="center">
      <l-map ref="map" style="width: 100%; height: 500px;" :zoom="zoom" :center="center">
        <l-draw-toolbar position="topleft" />
        <!-- <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer> -->
        <!-- <v-tilelayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></v-tilelayer> -->
        <v-tilelayer-googlemutant
          apikey="AIzaSyC3u9xA_vkVa7yBG3U-Pem3s0UveZvJyDE"
          lang="zh-TW"
          region="TW"
        ></v-tilelayer-googlemutant>
      </l-map>
    </v-row>
    <v-row>
      <v-col md="10">
        <v-textarea :value="geojson"></v-textarea>
      </v-col>
      <v-col lg="1">
        <v-btn color="success" @click="queryGeoJson">Get</v-btn>
      </v-col>
      <v-col lg="1">
        <v-btn color="secondary" @click="reset">Reset</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import * as Vue2Leaflet from "vue2-leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";
import Vue2LeafletGoogleMutant from "vue2-leaflet-googlemutant";
import "leaflet/dist/leaflet.css";
export default {
  name: "VueLeaflet",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LDrawToolbar,
    "v-tilelayer-googlemutant": Vue2LeafletGoogleMutant
  },
  data() {
    return {
      drawnItems: new L.FeatureGroup(),
      zoom: 16,
      center: L.latLng(25.0466309390259, 121.454783949554),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: L.latLng(25.0466309390259, 121.454783949554),
      text: "hello",
      geojson: "Geo Json Data",

      dialog: false,
      lbkey_no: 0
    };
  },
  mounted() {
    const currentObj = this;
    this.$refs.map.mapObject.on(L.Draw.Event.CREATED, function(event) {
      var layer = event.layer;
      currentObj.drawnItems.addLayer(layer);
      // let data = JSON.stringify(currentObj.drawnItems.toGeoJSON());
      // console.log(currentObj.drawnItems.toGeoJSON());

      // to "type":"FeatureCollection",
      let new_dict = {
        type: "FeatureCollection",
        features: []
      };

      currentObj.drawnItems.eachLayer(function(_layer) {
        let temp = currentObj.transformLayerToJson(_layer);
        console.log("temp");
        console.log(JSON.stringify(temp));
        new_dict["features"].push(temp);
      });
      new_dict = JSON.stringify(new_dict);

      currentObj.geojson = new_dict;
    });
  },
  beforeDestroy() {
    this.drawnItems = null;
  },
  methods: {
    transformLayerToJson(layer) {
      const json = layer.toGeoJSON();
      if (layer instanceof L.Circle) {
        json.properties.radius = layer.getRadius();
      }
      return json;
    },
    queryGeoJson() {
      console.log("query get json");
      // http://127.0.0.1:8888/engine/geojson/
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/engine/geojson/";
      const jsonData = {
        geojson: this.geojson
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, jsonData, config)
        .then(function(response) {
          console.log(response.data);
          currentObj.displayResult(response.data);
          // currentObj.dialog = true;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });
    },
    displayResult(data) {
      const info = JSON.parse(JSON.stringify(data));
      let status = info["status"];
      let lbkeys = info["lbkeys_ex"];
      let lbkey_no = info["lbkey_no"];
      this.lbkey_no = lbkey_no;

      console.log("lbkey count " + info["lbkeys"]);
      alert("找到地號" + this.lbkey_no + "\n ex:" + lbkeys);
    },
    reset() {
      const currentObj = this;
      this.drawnItems.eachLayer(function(layer) {
        currentObj.$refs.map.mapObject.removeLayer(layer);
        currentObj.geojson = "Geo Json Data";
      });
      this.drawnItems.clearLayers();
    }
  }
};
</script>
<style scoped>
.map {
  width: 100%;
  height: calc(100vh);
}
</style>
